import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'lib-flexible';
import './utils/rem';
import './assets/theme/index.css'
import 'normalize.css'
import './assets/css/bts.less'
import './assets/css/mine.less'
import './assets/css/iconfont/iconfont.css'
//单个图片上传
import ImgBigUpload from '@/components/ImgBigUpload'
import {
  hasPermission
} from '@/utils/permission.js'
Vue.prototype.$hasPerms = hasPermission

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.component('ImgBigUpload', ImgBigUpload)

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts



new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
